import Link from "next/link";
import { ReactNode, Suspense, useState } from "react";
import RankingChart from "@/components/RankingChart";
import { Text } from "@your-org/ui-lib";
import { TimeSeriesPoint } from "../../types.d/data";
import { ProductExt } from "@/pages/api/product";
import { RelatedProductLink } from "./ScrapingGridItem";
import style from "./DetailView.module.css";

function DetailImage({ image, title }: { image: string; title: string }) {
  const [loaded, setLoaded] = useState(false);
  const className = loaded ? "opacity-1" : "opacity-0";
  return (
    <div className={"h-96 w-96 bg-offwhite-normal dark:bg-offblack-normal transition " + className}>
      <img
        onLoad={() => setLoaded(true)}
        src={image}
        className="h-96 w-96 object-contain"
        alt={`${title} image`}
      />
    </div>
  );
}

function LabelWithText({
                         label,
                         children,
                       }: {
  label: string;
  children?: ReactNode | string;
}) {
  return (
    <div className="flex space-x-2">
      <Text className="opacity-40">{label}</Text>
      <Text>{children}</Text>
    </div>
  );
}

function DetailConfigurationDivider() {
  return <Text className="inline-block mr-1">{","}</Text>;
}

type DetailConfigurationProps = { product: ProductExt };

function DetailConfiguration({ product }: DetailConfigurationProps) {
  const { productType, relatedProducts } = product;

  return (
    <LabelWithText label="Configuration">
      <Text className="inline-block">{productType}</Text>
      {(!!relatedProducts?.length) && (
        <Text className="inline-block mx-1">{"(see also:"}</Text>
      )}
      {relatedProducts.map((relatedProduct, index) => (
        <div className="inline-block" key={relatedProduct.asin}>
          <RelatedProductLink
            asin={relatedProduct.asin}
            productType={relatedProduct.productType}
          />
          {relatedProducts[index + 1] && <DetailConfigurationDivider />}
        </div>
      ))}
      {(!!relatedProducts?.length) && (
        <Text className="inline-block">{")"}</Text>
      )}
    </LabelWithText>
  );
}

type ProductDetailViewProps = { product: ProductExt };

export function ProductDetailView({ product }: ProductDetailViewProps) {
  const {
    asin,
    coverUrl,
    artist,
    title,
    price,
    label,
    bestsellerRank,
    highestPosition,
    currentPosition,
    productUrl,
    rankedDays,
  } = product;

  // todo correct
  const updatedAt = new Date(product.updatedAt).toLocaleDateString("en");
  const rdRaw = product.releaseDate && new Date(product.releaseDate);
  const releaseDate = rdRaw ? rdRaw.toLocaleDateString("en") : "-";
  // `${rdRaw.getDate()}/${rdRaw.getMonth() + 1}/${rdRaw.getFullYear().toString().substring(2)}`
  const rankedDaysAll = product.allPositions as unknown as TimeSeriesPoint[];

  return (
    <div className="flex space-x-10">
      <div className="">
        <DetailImage image={coverUrl} title={title} />
      </div>
      <div className="flex justify-between relative flex-1">
        <div>
          <Text variant="h2" className="line-clamp-1">
            {title}
          </Text>
          <Text variant="h3">{artist}</Text>

          <div className="mb-[50px]" />

          <div className="flex flex-wrap space-y-[5px]">
            <div className="space-y-[5px] pr-4">
              <LabelWithText label="Releasedate">{releaseDate}</LabelWithText>
              <div>
                <div className="mb-[25px]" />
              </div>
              <LabelWithText label="Label">{label}</LabelWithText>
              <LabelWithText label="ID / ASIN">{asin}</LabelWithText>
              <DetailConfiguration product={product} />
              <LabelWithText label="Price">{price}</LabelWithText>
              <LabelWithText label="Country">Germany</LabelWithText>
              {bestsellerRank && (
                <div className="space-y-[5px]">
                  <LabelWithText label="Bestseller-rank" />
                  <Text
                    className={`space-y-[5px] ${style.bestsellerRankText}`}
                    dangerouslySetInnerHTML={{ __html: bestsellerRank }}
                  />
                </div>
              )}
            </div>
            <div className="space-y-[5px]">
              <LabelWithText label="Last import">{updatedAt}</LabelWithText>
              <div>
                <div className="mb-[25px]" />
              </div>
              <LabelWithText label="Highest rank">
                {highestPosition}
              </LabelWithText>
              <LabelWithText label="Ranked days">{rankedDays}</LabelWithText>
              <LabelWithText label="Current rank">
                {currentPosition !== 0 ? currentPosition : "-"}
              </LabelWithText>
            </div>
          </div>

          <div className="mb-[25px]" />

          <Link href={productUrl} target="_blank">
            <Text className="underline" variant="menu">
              Go to Amazon Page →
            </Text>
          </Link>

          <div className="mb-[50px]" />

          <LabelWithText label="Ranking development" />
          <Suspense>
            <RankingChart points={rankedDaysAll} />
          </Suspense>
        </div>
      </div>
    </div>
  );
}
