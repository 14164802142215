import Row from "../Row";
import Text from "../Text";
import cn from "classnames";
import s from "./DarkModeToggle.module.css";

interface DarkModeToggleProps {
  theme: string | undefined; //
  setTheme: (theme: string) => void; // todo add react hooks setter type
}

/**
 * small switch to toggle dark mode
 * @todo should be refactored to a simple switch component
 * @param theme
 * @param setTheme
 * @constructor
 */
export function DarkModeToggle({ theme, setTheme }: DarkModeToggleProps) {
  const darkMode = theme === "dark";

  if (!theme) {
    return null;
  }

  function toggleTheme() {
    console.log("toggleTheme", darkMode);
    setTheme(!darkMode ? "dark" : "light");
  }

  return (
    <Row className="" center>
      <Text variant="menu" className="pr-4">Mode</Text>
      <div
        className={cn(s.toggle, darkMode && s.toggleSelected)}
        onClick={toggleTheme}
      >
        <div
          className={cn(s.dot, darkMode && s.dotSelected)}
        />
      </div>
    </Row>
  );
}

export default DarkModeToggle;
