import { NextRouter, useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

/**
 * set/add a value to url query params
 * - if the value already existing nothing wil happens
 * - set multi values by default
 * @param key
 * @param value
 * @param router
 * @param {boolean} multi set false if you want the value to be a single value and replace the old one
 */
export function setUrlParam(key: string, value: any, router: NextRouter, multi = true) {
  const current = router.query[key];
  // already existing -> skip
  if (current && current.includes(value)) return;
  // non existing
  if (!current) router.query[key] = value;
  // one single existing
  else if (typeof current === 'string') {
    router.query[key] = multi ? [current, value] : value;
  } else current.push(value); // use current again for TS

  return updateQueryParams(router);
}

/**
 * Update the url query params (Add new params and remove old ones)
 * Need the update in router.query and than the url updates with shallow update
 *
 * @param {NextRouter} router
 */
export function updateQueryParams(router: NextRouter) {
  const basePath = router.asPath.split('?')[0] || router.asPath;
  const { query } = router;

  // cleanup query cause next.js add dynamic route here
  Object.keys(query).map(function (k) {
    if (router.pathname.includes(`[${k}]`)) {
      delete query[k];
    }
  });

  const url = { pathname: router.pathname, query };
  const as = { pathname: basePath, query };

  return router.push(url, as, { shallow: true });
}

/**
 * use filter hook
 * - the hook will be called a lot but the return is memorized so rerendering is less
 * @param filter
 * @param multi allow set the same key multi times
 */
export function useFilter(filter: string, multi = true) {
  const router = useRouter();
  /**
   * the selected filter value(s)
   */
  const selectedFilter = useMemo(() => router.query[filter], [router.query]);
  console.log('%cuseFilter:' + filter, 'background: #222; color: #bada55', selectedFilter);
  /**
   * add or update a filter
   *
   * @param newValue
   * @param oldValue Todo why given oldValue when not in use?
   */
  const updateFilterCB = (newValue: string | number, oldValue = undefined) => {
    console.log('%cupdateFilter', 'color: #bada55', newValue, oldValue, router);
    void setUrlParam(filter, newValue, router, multi); // update the given value
  };

  const updateFilter = useCallback(updateFilterCB, [router.query]);

  /**
   * provide a value for selecting one filter value to remove, otherwise all will be removed
   * @param value
   */
  const removeFilterCb = (value: string | undefined = undefined) => {
    console.log('%cremoveFilter', 'color: #bada55', value, filter, router);
    if (!value || !Array.isArray(selectedFilter)) delete router.query[filter];
    else router.query[filter] = selectedFilter.filter(i => i !== value); // remove the item

    return updateQueryParams(router);
  };

  const removeFilter = useCallback(removeFilterCb, [router.query]);

  return { selectedFilter, removeFilter, updateFilter };
}
