import { Button, Card, CheckBox, FilterModal, Row, Text, useFilter } from "@your-org/ui-lib";
import { useEffect, useState } from "react";
import { useStatus } from "../hooks/useStatus";
import Link from "next/link";
import { FILTER, Filter, STATUS } from "@/lib/const";

function ApcLink() {
  const apcUrl =
    "https://www.amazon.de/s?i=popular&rh=n%3A255882%2Cp_69%3A0x-90y&lo=image&pf_rd_i=255882&pf_rd_m=A3JWKAKR8XB7XF&pf_rd_p=2a3b33d0-89b2-4827-8cce-21c92f69508e%2C2a3b33d0-89b2-4827-8cce-21c92f69508e&pf_rd_r=AXXE9CJEFPDT294QXSTJ%2CAXXE9CJEFPDT294QXSTJ&pf_rd_s=merchandised-search-left-4&pf_rd_t=101&qid=1657789380&ref=sr_pg_1";

  return (
    <Link href={apcUrl} target="_blank">
      <Text className="underline inline-block" variant="body">
        Amazon List
      </Text>
    </Link>
  );
}

function Status({ title = "", currentCount = 0 }) {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex mb-10">
      <Text variant="h2">{title}</Text>
      <div className="relative flex flex-col justify-between">
        {/*<Button variant="gray" onClick={triggerUpdate}>update</Button>*/}
        <Button
          variant="gray"
          className="rounded-full w-6 h-6"
          onClick={() => setOpen(true)}
        >
          ?
        </Button>
        <FilterModal
          show={open}
          close={() => setOpen(false)}
          background="gray"
          text="help"
          className="-top-2 w-96"
          padding
        >
          <div className="mt-5 mb-[9px] space-y-5">
            <div className="max-w-lg space-y-[5px]">
              <Text variant="ha">SOURCE</Text>
              <Text>
                The first 100 items from the <ApcLink /> are imported once per day. The scraped data serves as basis for
                entries in this tool.
              </Text>
            </div>

            <div className="max-w-lg space-y-[5px]">
              <Text variant="ha">GROUP</Text>
              <Text>
                Click on group to unfold other versions of the product (please note that this only includes products
                that are linked to each other within the Amazon product page). Click on "Show related products" to
                unfold all groups.
              </Text>
            </div>

            <div className="max-w-lg space-y-[5px]">
              <Text variant="ha">RANK</Text>
              <Text>
                The rank represents the position of the product on the <ApcLink />. Highest rank is the highest position
                the product was ranked on based on all imports, ranked days represent the number of days the product was
                imported
              </Text>
            </div>

            <div className="max-w-lg space-y-[5px]">
              <Text variant="ha">DETAIL VIEW</Text>
              <Text>
                Click on a product to unfold the product detail view. All information is imported directly from the
                Amazon product page. You can click on the fields described below below to filter the data. A border
                around the field shows that the filter is active.
              </Text>
            </div>

            <div className="max-w-lg space-y-[5px]">
              <Text variant="ha">UPCOMING RELEASES</Text>
              <Text>
                This includes all future releases that have been imported from the <ApcLink /> so far.
              </Text>
            </div>

            <div className="max-w-lg space-y-[5px]">
              <Text variant="ha">LATEST IMPORTS</Text>
              <Text>
                This includes all releases from the last data import.
              </Text>
            </div>

            <div className="max-w-lg space-y-[5px]">
              <Text variant="ha">NEW IMPORTS</Text>
              <Text>
                All releases that have been imported for the first time in the last 7 days.
              </Text>
            </div>

            <div className="max-w-lg space-y-[5px]">
              <Text variant="ha">POSTPONED</Text>
              <Text>
                All releases with changed release dates within the last 30 days.
              </Text>
            </div>
          </div>
        </FilterModal>
        <Text variant="ha" className="mb-4">({currentCount})</Text>
      </div>
    </div>
  );
}

export function StatusInfoWrapped({ currentCount = 0, title = "" }) {
  const { loading, upcoming, lastImport, newCount, postponedCount } = useStatus();

  return (
    <StatusInfo
      loading={loading}
      upcomingReleases={upcoming}
      lastImport={lastImport}
      newCount={newCount}
      postponedCount={postponedCount}
      showUpcoming
      currentCount={currentCount}
      title={title}
    />
  );
}

type StatusInfoProps = {
  loading: boolean;
  showUpcoming?: boolean;
  upcomingReleases?: number | string;
  lastImport: number | string;
  newCount: number | string;
  postponedCount: number | string;
  currentCount?: number;
  title?: string;
};

export function StatusInfo({
                             loading,
                             upcomingReleases,
                             lastImport,
                             postponedCount,
                             newCount,
                             showUpcoming = false,
                             currentCount = 0,
                             title = "",
                           }: StatusInfoProps) {
  // note - no loading required,
  // todo maybe add some effect so show loading page in general?
  // if (loading) return <div>loadings</div>;

  const { selectedFilter, updateFilter, removeFilter } = useFilter(
    FILTER.STATUS,
  );

  function selectOption(value: Filter) {
    console.log("selectOption", value);

    if (selectedFilter && selectedFilter.includes(value)) {
      removeFilter(value);
    } else {
      if (value) updateFilter(value);
    }
  }

  // this filter should be true by default
  useEffect(() => {
    if (!selectedFilter?.includes(STATUS.LATEST)) updateFilter(STATUS.LATEST);
  }, []);

  function showOutline(value: Filter) {
    return selectedFilter && selectedFilter.includes(value)
      ? " outline outline-2 outline-offset-2 dark:outline-offwhite-normal"
      : "";
  }

  return (
    <>
      <Status title={title} currentCount={currentCount} />

      <div className="flex space-x-3">
        {showUpcoming && (
          <Card
            className={"cursor-pointer" + showOutline(STATUS.UPCOMING)}
            onClick={() => selectOption(STATUS.UPCOMING)}
          >
            <Row between center>
              <Row center className="space-x-2">
                <CheckBox checked={!!selectedFilter?.includes(STATUS.UPCOMING)} />
                <Text variant="link">Upcoming Releases</Text>
              </Row>
              <Text>{upcomingReleases}</Text>
            </Row>
          </Card>
        )}
        <Card
          className={"cursor-pointer" + showOutline(STATUS.LATEST)}
          onClick={() => selectOption(STATUS.LATEST)}
        >
          <Row between center>
            <Row center className="space-x-2">
              <CheckBox checked={!!selectedFilter?.includes(STATUS.LATEST)} />
              <Text variant="link">Latest import</Text>
            </Row>
            <Text>{lastImport}</Text>
          </Row>
        </Card>
        <Card
          variant="yellow"
          className={"cursor-pointer" + showOutline(STATUS.NEW)}
          onClick={() => selectOption(STATUS.NEW)}
        >
          <Row between center>
            <Row center className="space-x-2">
              <CheckBox checked={!!selectedFilter?.includes(STATUS.NEW)} />
              <Text variant="link">New</Text>
            </Row>
            <Text>{newCount}</Text>
          </Row>
        </Card>
        <Card
          variant="orange"
          className={"cursor-pointer" + showOutline(STATUS.POSTPONED)}
          onClick={() => selectOption(STATUS.POSTPONED)}
        >
          <Row between center>
            <Row center className="space-x-2">
              <CheckBox checked={!!selectedFilter?.includes(STATUS.POSTPONED)} />
              <Text variant="link">Postponed</Text>
            </Row>
            <Text>{postponedCount}</Text>
          </Row>
        </Card>
      </div>
    </>
  );
}
