import { useTheme } from 'next-themes';
import { Text } from '@your-org/ui-lib';

interface NoResultsProps {
  description: string;
}
export function NoResults({ description }: NoResultsProps) {
  const { theme } = useTheme();

  return (
    <div className="max-h-full w-full flex justify-center items-center flex-col pt-10">
      <img
        src={`/assets/${theme === 'dark' ? 'noResultsLogoWhite' : 'noResultsLogo'}.png`}
        alt="No entries found"
        className="object-contain h-48 w-full"
      />
      <Text variant="link" className="max-w-2xl text-center pt-10">
        {description}
      </Text>
    </div>
  );
}
