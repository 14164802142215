import { Fragment, useEffect, useState } from "react";
import Link from "next/link";
import cn from "classnames";
import { Text } from "@your-org/ui-lib";
import style from "./ScrapingGrid.module.css";
import { ProductDetailView } from "./DetailView";
import { ProductExt } from "@/pages/api/product";

function ArrowOnSquare() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
         className="w-4 h-4">
      <path strokeLinecap="round" strokeLinejoin="round"
            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
    </svg>);
}

function ArrowElbowDownRight() {
  return (
    <div className="flex items-center justify-center">
      <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.53516 8.35156L12.2128 11.1641L9.53516 13.9766" stroke="black" stroke-linecap="round"
              stroke-linejoin="round" />
        <path d="M4.17969 2.72656V11.1641H12.2125" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </div>
  );
}

function TileImage({ image, title }: { image: string; title: string }) {
  return (
    <div className="scraping-img-container">
      <img
        src={image}
        className="scraping-img h-16 rounded-l"
        alt={`${title} image`}
      />
    </div>
  );
}

type RelatedProductLinkProps = {
  asin: string;
  productType: string;
  cw?: number;
};

export function RelatedProductLink(
  { asin, productType, cw }: RelatedProductLinkProps,
) {
  return (
    <span className="underline cursor-pointer">
      <Link href={`#${asin}`} legacyBehavior>
        <a onClick={(event) => event.stopPropagation()}>
          {productType} {cw && <span>(KW#{cw})</span>}
        </a>
      </Link>
    </span>
  );
}

type ConfigurationProps = {
  relatedProducts: ProductExt["relatedProducts"];
  productType: ProductExt["productType"];
  /**
   * CW of the main product
   */
  mainCw?: number;
};

/*@ts-ignore*/
function Configuration({ relatedProducts, productType, mainCw }: ConfigurationProps) {
  const mainProdcuts = relatedProducts.filter(p => p.main);
  const relatedCount = relatedProducts.length - mainProdcuts.length;
  return (
    <Text className="line-clamp-2">
      {productType} {relatedCount > 0 && <span>({relatedCount})</span>}
      {relatedProducts.filter(p => p.main).map((relatedProduct, i) => {
        /*@ts-ignore*/
        const cw = mainCw && relatedProduct.cw !== mainCw ? mainCw : undefined;
        return (
          <Fragment key={i}>
            <span className="mx-1">/</span>
            <RelatedProductLink
              asin={relatedProduct.asin}
              productType={relatedProduct.productType}
              cw={cw}
            />
          </Fragment>
        );
      })}
    </Text>
  );
}

function renderPrice(price: string = '') {
  if(!price?.length) return '-'
  const p = price.split('€')[0].trim()
  return `${p} €`
}

/*
* Todo: add isNew? */
function SubGridItem({ rProduct }: { rProduct: ProductExt["relatedProducts"] }) {
  // @ts-ignore
  const bgGray = !rProduct.isNew && !rProduct.isPostponed;
  const className = cn(
    style.subItem,
    {
      [style.default]: bgGray,
      // @ts-ignore
      [style.new]: rProduct.isNew,
      // @ts-ignore
      [style.postponed]: rProduct.isPostponed, // @ts-ignore
    },
    // itemSelected && 'border-2 border-offblack-normal dark:border-offwhite-normal'
  );
  return <div className={className}>
    <div className="w-full h-full bg-white" />
    <ArrowElbowDownRight />
    <div className="relative">
      {/*<Text className="absolute !text-xs -top-3  -left-3">{rProduct.asin}</Text>*/}
      <Text className="line-clamp-1">{rProduct.artist}</Text>
    </div>
    <Text className="line-clamp-1">{rProduct.title}</Text>
    <Text className="line-clamp-1">{rProduct.label}</Text>
    <Configuration
      productType={rProduct.productType}
      // @ts-ignore
      relatedProducts={rProduct.relatedProducts}
    />
    <Text>{renderPrice(rProduct.price ?? undefined)}</Text>
    <Text>{rProduct.currentPosition || "-"}</Text>
    <Text>{rProduct.highestPosition || "-"}</Text>
    <Text>{rProduct.rankedDays || "-"}</Text>
    <a href={rProduct.productUrl} onClick={(event) => event.stopPropagation()} target="_blank">
      <span className="cursor-pointer flex items-center justify-center">
        <ArrowOnSquare />
      </span>
    </a>
  </div>;
}

type ScrapingGridItemProps = { selected?: boolean; product: ProductExt, show?: boolean };

export function ScrapingGridItem(
  { selected = false, product, show = false }: ScrapingGridItemProps,
) {
  const [showRelatedProducts, setShowRelatedProducts] = useState(show);
  const [itemSelected, setItemSelected] = useState(selected);

  useEffect(() => {
    if (show !== showRelatedProducts) setShowRelatedProducts(show);
  }, [show]);

  function toggleShowRelatedProducts(e) {
    e.stopPropagation();
    setShowRelatedProducts(s => !s);
  }

  const {
    asin,
    coverUrl,
    artist,
    title,
    label,
    highestPosition,
    currentPosition,
    isNew,
    isPostponed,
    rankedDays,
    price
  } = product;

  // default
  const bgGray = !isNew && !isPostponed;
  const className = cn(
    style.item,
    {
      [style.default]: bgGray,
      [style.new]: isNew,
      [style.postponed]: isPostponed,
    },
    // itemSelected && 'border-2 border-offblack-normal dark:border-offwhite-normal'
  );

  const relatedProducts = product.relatedProducts.filter(p => !p.main);
  // const relatedProducts = [...product.relatedProducts, ...(product.relatedProductsRelations || [])].reduce((acc, relatedProduct) => {
  //   if (!acc.find((p) => p.asin === relatedProduct.asin)) {
  //     acc.push(relatedProduct);
  //   }
  //   return acc;
  // }, [] as ProductExt["relatedProducts"]);
  return (
    <div className="flex flex-col gap-1">
      <div
        className={className}
        id={asin}
        onClick={() => setItemSelected(!itemSelected)}
      >
        <TileImage image={coverUrl} title={title} />
        {relatedProducts.length ? <Text
          center
          className="rounded-full bg-black text-white !text-xs cursor-pointer"
          variant="body"
          onClick={toggleShowRelatedProducts}
        >
          Group
        </Text> : <div />}
        <div className="relative">
          {/*<Text className="absolute !text-xs -top-6 -left-3">{asin}</Text>*/}
          <Text className="line-clamp-2">{artist}</Text>
        </div>
        <Text className="line-clamp-2">{title}</Text>
        <Text className="line-clamp-2">{label}</Text>
        <Configuration
          productType={product.productType}
          relatedProducts={product.relatedProducts}
          mainCw={product.cw}
        />
        <Text>{renderPrice(price ?? undefined)}</Text>
        <Text>{currentPosition || "-"}</Text>
        <Text>{highestPosition || "-"}</Text>
        <Text>{rankedDays || "-"}</Text>
        <a href={product.productUrl} onClick={(event) => event.stopPropagation()} target="_blank">
          <span className="cursor-pointer flex items-center justify-center">
            <ArrowOnSquare />
          </span>
        </a>
      </div>
      {itemSelected && (
        <ProductDetailView product={product} />
      )}
      {/*scroll link to sub items not working with asin. todo link to main product maybe? */}
      {showRelatedProducts && relatedProducts.map((rProduct, i) => (
        <SubGridItem rProduct={rProduct} key={`r-${asin}-${i}`} />
      ))}
    </div>
  );
}
