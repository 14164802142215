import cn from 'classnames';
import { ReactNode } from 'react';

type RowProps = {
  /**
   * resolves to justify-between, only works if row has enough space
   */
  between?: boolean;
  /**
   * If true, the content in the column will be centered
   */
  center?: boolean;
  /**
   * space between each item, only from tailwind supported values allowed (1, 2, 4,...)   */
  space?: number | string;
  /**
   * use for extending the styling
   */
  className?: string;
  children: ReactNode;
};

/**
 * Helper row component
 * @param between justify:space-between
 * @param center align-items: center
 * @param space
 * @param children
 * @param className potential other classnames
 * @constructor
 */
function Row({ between = false, center = false, space = 0, children, className }: RowProps) {
  const classes = cn(
    'flex',
    {
      'justify-between': between,
      'items-center': center,
    },
    space && `space-x-${space}`,
    className,
  );
  return <div className={classes}>{children}</div>;
}

export default Row;
