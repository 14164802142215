const CheckboxSVGUnchecked = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="13" height="13" rx="2.5" />
      {/*<rect x="0.4" y="0.493262" width="10.2933" height="10.2933" strokeWidth="0.8" />*/}
    </svg>
  );
};

// todo this attribute prevent change color in DM :O
const CheckboxSVGChecked = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      stroke="currentColor"
      strokeWidth="0.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.8539 4.8539C10.9478 4.76001 11.0005 4.63268 11.0005 4.4999C11.0005 4.36712 10.9478 4.23979 10.8539 4.1459C10.76 4.05201 10.6327 3.99927 10.4999 3.99927C10.3671 3.99927 10.2398 4.05201 10.1459 4.1459L5.4999 8.7929L3.8539 7.1459C3.76001 7.05201 3.63268 6.99927 3.4999 6.99927C3.36712 6.99927 3.23979 7.05201 3.1459 7.1459C3.05201 7.23979 2.99927 7.36712 2.99927 7.4999C2.99927 7.63268 3.05201 7.76001 3.1459 7.8539L5.1459 9.8539C5.19234 9.90046 5.24752 9.93741 5.30827 9.96261C5.36901 9.98782 5.43413 10.0008 5.4999 10.0008C5.56567 10.0008 5.63079 9.98782 5.69153 9.96261C5.75228 9.93741 5.80745 9.90046 5.8539 9.8539L10.8539 4.8539Z" />
      <path d="M2.682 0C1.97069 0 1.28851 0.282567 0.78554 0.78554C0.282567 1.28851 0 1.97069 0 2.682V11.318C0 12.8 1.2 14 2.682 14H11.318C12.8 14 14 12.8 14 11.318V2.682C14 1.2 12.8 0 11.318 0H2.682ZM1 2.682C1 1.753 1.753 1 2.682 1H11.318C12.247 1 13 1.753 13 2.682V11.318C13 12.247 12.247 13 11.318 13H2.682C2.23591 13 1.80808 12.8228 1.49265 12.5074C1.17721 12.1919 1 11.7641 1 11.318V2.682Z" />
    </svg>
  );
};

export default function CheckBox({ checked }: { checked: boolean }) {
  return checked ? <CheckboxSVGChecked /> : <CheckboxSVGUnchecked />;
}
