import { Text } from "@your-org/ui-lib";

type OptionSelectProps = {
  select: () => void;
  value: string
}

export function OptionSelect({ select, value }: OptionSelectProps) {
  return (
    <Text
      className="cursor-pointer hover:bg-offblack-light hover:text-white dark:hover:bg-offwhite-dark dark:hover:text-black rounded py-1 px-2"
      onClick={select}
    >
      {value}
    </Text>);
}

export default OptionSelect;
