import type { ReactNode } from "react";
import React, { memo } from "react";
import { MainFooter } from "@/components/layout/MainFooter";
import { HeaderText, MainHeader } from "@/components/layout/MainHeader";
//
// const HeaderMemo = memo(({headerText}: {headerText?: string}) => {
//   return headerText ?  <HeaderText text={headerText} />: <MainHeader />
// }, (p, n) => p.headerText === n.headerText  )

export function MainLayout({ children, headerText, bg = '', dark = false }: { children: ReactNode, headerText?: string, bg?: string, dark?: boolean }) {
  const className = `flex flex-col h-screen ${bg}`;

  return (
    <div className={className}>
      {/*<HeaderMemo headerText={headerText}/>*/}
      {headerText ?  <HeaderText text={headerText} />: <MainHeader />}
      <main className="p-2 sm:p-4 lg:p-6 flex-1">{children}</main>
      <MainFooter dark={dark}/>
    </div>
  );
};
