import Link from "next/link";
import { ReactNode } from "react";
import { UrlObject } from "url";
import { Text } from "../Text";

type Props = {
  /**
   * The text to display in the link mostly, but can by any ReactNode
   */
  children?: ReactNode;
  /**
   * The URL to link to
   */
  href?: string | UrlObject;
  /**
   * With this prop you can specify a function to call when clicking on the link
   * Note: The function should bedder use "preventDefault" i guess.
   */
  onClick?: () => void;
};

/**
 * Primary UI component for use in Header or where the user can route to a different page
 */
export function NavItem({ href = "", children, onClick }: Props) {
  return (
    <li onClick={onClick}>
      <Link href={href}>
        <Text variant="menu" underline="h1">
          {children}
        </Text>
      </Link>
    </li>
  );
}

export default NavItem;
