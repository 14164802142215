import {
  FunctionComponent,
  JSXElementConstructor,
  ReactNode,
  ComponentType,
  HTMLAttributes,
  ReactElement,
} from "react";
import cn from "classnames";
import s from "./Text.module.css";

export type Variant = 'big' | 'h1' | 'h2' | 'h3' | 'ha' | 'link' | 'menu' | 'body';

export type Underline = 'h1' | 'h2' | 'h4';

export interface TextProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * change the style of the text
   * translation to figma
   * - big -> big
   * - HEADLINE -> h1
   * - HEADLINE 2 -> h2
   * - HEADLINE 3 -> h3
   * - HEADLINE ADDITION -> ha
   * - LINK -> link
   * - MENU -> menu
   * - COPY -> body (default)
   */
  variant?: Variant;
  /**
   * add a underline animation when the user hovers over the text
   */
  underline?: Underline;
  /**
   * center the text
   */
  center?: boolean;
  /**
   * Extend or overwrite the style with css classes
   */
  className?: string;
  /**
   * The content that should get the intended styling
   */
  children?: ReactNode | string;
}

/**
 * UI component for rendering text with different, predefined styles
 */
export const Text: FunctionComponent<TextProps> = ({
  variant = 'body',
  center = false,
  className = '',
  underline,
  children,
  ...props
}) => {
  const componentsMap: {
    [P in Variant]: ComponentType<any> | string;
  } = {
    big: 'h1',
    h1: 'h2',
    h2: 'h2',
    h3: 'h3',
    ha: 'h4',
    link: 'div',
    menu: 'div',
    body: 'div',
  };

  const Component:
    | JSXElementConstructor<any>
    | ReactElement<any>
    | ComponentType<any>
    | string = componentsMap![variant!];

  const title = (typeof children === 'string' || children instanceof String) ? String(children) : ''

  return (
    <Component
      title={title}
      className={cn(
        s.root,
        { 'text-center': center },
        {
          [s.big]: variant === 'big',
          [s.h1]: variant === 'h1',
          [s.h2]: variant === 'h2',
          [s.h3]: variant === 'h3',
          [s.ha]: variant === 'ha',
          [s.link]: variant === 'link',
          [s.menu]: variant === 'menu',
          [s.body]: variant === 'body',
        },
        {
          [s['underline-h-1']]: underline === 'h1',
          [s['underline-h-2']]: underline === 'h2',
          [s['underline-h-4']]: underline === 'h4',
        },
        className,
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

type TextLoadingProps = TextProps & {
  h?: number;
};

export function TextLoading({ h = 3, variant = 'body', children }: TextLoadingProps) {
  if (!children)
    return (
      <div className="p-1 w-full">
        <div className={`h-${h} w-full bg-theme-light rounded animate-pulse`} />
      </div>
    );
  return <Text variant={variant}>{children}</Text>;
}

export default Text;
