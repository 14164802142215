import Link from "next/link";
import { motion } from "framer-motion";
import { useTheme } from "next-themes";
import { Text } from "../Text";

//  https://codesandbox.io/s/rutrh?module=/src/Example.tsx&file=/src/styles.css:404-534
export function WarnerLogoSVG({ width = 225, height = 151, dark = false }) {
  return (
    <div>
      <motion.svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        className="logo__animated"
        viewBox="0 0 580 390"
      >
        <motion.path
          d="m176.39,387.13c-27.95,-1.05 -52.68,-14.42 -65.19,-43.83c-33.81,-79.51 -66.96,-159.32 -99.26,-239.45c-15.51,-38.49 3.49,-80.24 41.23,-95.75c36.67,-15.07 80.04,1.22 95.99,38.57c34.09,79.87 67.73,159.96 99.63,240.72c19.09,48.32 -17.73,99.8 -72.4,99.74"
          variants={{
            hidden: {
              pathLength: 0,
              fill: "rgba(0,0,0,0)",
            },
            visible: {
              pathLength: 1,
              fill: dark ? "rgb(255,255,255)" : "rgba(0,0,0,1)",
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            default: { duration: 1, ease: "easeInOut" },
            fill: { duration: 1, ease: [1, 0, 0.8, 1] },
          }}
        />
        <motion.path
          d="m373.26,387.13c-27.78,-1.12 -52.46,-14.5 -64.96,-43.93c-33.79,-79.52 -66.97,-159.31 -99.26,-239.45c-15.5,-38.48 3.58,-80.18 41.36,-95.68c36.67,-15.04 79.93,1.31 95.92,38.71c33.95,79.45 67.31,159.17 99.31,239.42c19.5,48.92 -17.01,101.06 -72.37,100.93"
          variants={{
            hidden: {
              pathLength: 0,
              fill: "rgba(0,0,0,0)",
            },
            visible: {
              pathLength: 1,
              fill: dark ? "rgb(255,255,255)" : "rgba(0,0,0,1)",
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            default: { delay: 0.15, duration: 1, ease: "easeInOut" },
            fill: { delay: 0.15, duration: 1, ease: [1, 0, 0.8, 1] },
          }}
        />
        <motion.path
          d="m497.36,208.66c-28.66,-0.89 -52.76,-14.85 -66.32,-44.36c-9.05,-19.68 -17.23,-39.8 -24.87,-60.07c-14.63,-38.81 3.19,-80.12 40.74,-95.94c36.44,-15.35 79.06,0.93 96.21,37.72c9.7,20.82 18.67,42.1 26.33,63.74c17.05,48.22 -18.24,98.49 -72.09,98.91"
          variants={{
            hidden: {
              pathLength: 0,
              fill: "rgba(0,0,0,0)",
            },
            visible: {
              pathLength: 1,
              fill: dark ? "rgb(255,255,255)" : "rgba(0,0,0,1)",
            },
          }}
          initial="hidden"
          animate="visible"
          transition={{
            default: { delay: 0.3, duration: 1, ease: "easeInOut" },
            fill: { delay: 0.3, duration: 1, ease: [1, 0, 0.8, 1] },
          }}
        />
      </motion.svg>
    </div>
  );
}

export interface LogoProps {
  /**
   * Overwrite the url where the user will redirect to when clicking the logo
   */
  href?: string;
  /**
   * If true, the logo will be always dark, regardless of the theme
   */
  logoDark?: boolean;
  /**
   * the 3 string that will be displayed next to the logo
   */
  text?: string[];
}

export const Logo = ({
                       href = "/",
                       logoDark = false,
                       text = ["playlist", "position", "report"],
                     }: LogoProps) => {
  const { theme } = useTheme();

  const dark = logoDark ? false : theme !== "light";
  return (
    <div className="flex-1 self-center">
      <div className="flex flex-row">
        <Link href={`${href}`} className="flex items-center mr-2">
          <WarnerLogoSVG width={81} height={54} dark={dark} />
        </Link>
        <div>
          {text.map(t => (
            <Text key={t} variant="menu" className={logoDark ? "text-black" : ""}>{t}</Text>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Logo;
