import React, { useCallback, useMemo } from "react";
import { bisector, extent } from "d3-array";
import * as allCurves from "@vx/curve";
import { Group } from "@vx/group";
import { Line, LinePath } from "@vx/shape";
import { scaleLinear, scaleTime } from "@vx/scale";
import { TimeSeriesPoint } from "types.d/data";
import { AxisBottom, AxisLeft } from "@vx/axis";
import { timeFormat } from "d3-time-format";
import { TODAY, TWENTY_EIGHT_DAYS_AGO } from "@/lib/const";
import { ParentSize } from "@vx/responsive";
import { defaultStyles, Tooltip, useTooltip } from "@vx/tooltip";
import { localPoint } from "@vx/event";
import { useTheme } from "next-themes";


// data accessors
const getDate = (d: TimeSeriesPoint): Date => new Date(new Date(d.time).setHours(0, 0, 0, 0));
const getPosition = (d: TimeSeriesPoint) => d.position;

const background = "#3b6978";
const purple1 = "#6c5efb";
const purple2 = "rgba(201,152,255, 05)";
export const accentColorDark = "#75daad";

// export const strokeLine = "#a44afe";

const tooltipStyles = {
  ...defaultStyles,
  background,
  border: "1px solid white",
  color: "white",
};

export type LineChartProps = {
  width: number;
  height: number;
  points: TimeSeriesPoint[];
  /**
   * Color of the chart - defined by parent component to avoid recalculation in chart where "mouse move" spams
   */
  strokeLine: string;
};

const margin = {
  // top: 30,
  top: 10,
  // left: 50,
  left: 35,
  // bottom: 40,
  bottom: 25,
  // right: 30,
  right: 20,
};

const minDate = TWENTY_EIGHT_DAYS_AGO;
const maxDate = TODAY;
const positionTicks = [1, 96];


function LineChart({
 width = 400, height = 200, points, strokeLine
}: LineChartProps) {
  // console.log("R:LineChart", points, generatePoints(points));
  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<TimeSeriesPoint>();

  if (!points.length) return null;
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // todo test scaleUtc
  const dateScale = useMemo(() => scaleTime<number | undefined>({
    domain: [minDate, maxDate],
    range: [0, xMax],
  }), [xMax, points]);

  const positionScale = useMemo(() => scaleLinear<number>({
    domain: [1, 96],
    range: [0, yMax],
  }), [yMax, points]);

  function formatDate(date: Date) {
    return timeFormat("%b %d")(date);
  }

  function formatPosition(p: number): string {
    return p === 96 ? "> 96" : "1";
  }

  const handleMouseMove = useCallback((event: React.MouseEvent<SVGRectElement>) => {
    console.log("handleMouseMove");
    // const { x } = localPoint(event) || { x: 0 };
    // const x0 = dateScale.invert(x);
    // const bisectDate = bisector<TimeSeriesPoint, Date>(d => new Date(d.time)).left;
    //
    // const index = bisectDate(points, x0, 1);
    // const d0 = points[index - 1];
    // const d1 = points[index];
    // console.log({ x, x0, index, d0, d1 });
    // let d = d0;
    // if (d1 && getDate(d1)) {
    //   d = x0.valueOf() - getDate(d0).valueOf() > getDate(d1).valueOf() - x0.valueOf() ? d1 : d0;
    // }
    // showTooltip({
    //   tooltipData: d,
    //   tooltipLeft: x,
    //   tooltipTop: positionScale(getPosition(d)),
    // });
  }, [showTooltip, dateScale, positionScale]);

  return (
    <div className="py-4 relative">
      <svg width={width} height={height}>
        {/*<rect width={width} height={height} fill="#efefef" rx={5} ry={5} />*/}
        <Group top={margin.top} left={margin.left}>
          {/*{true &&*/}
          {/*  points.map((d, j) => (*/}
          {/*    <circle*/}
          {/*      key={j}*/}
          {/*      r={2}*/}
          {/*      cx={dateScale(getDate(d))}*/}
          {/*      cy={positionScale(getPosition(d))}*/}
          {/*      stroke={purple1}*/}
          {/*      fill={purple2}*/}
          {/*    />*/}
          {/*  ))}*/}
          <LinePath<TimeSeriesPoint>
            height={yMax}
            curve={allCurves["curveCatmullRom"]}
            // curve={allCurves["curveLinear"]}
            data={points}
            // @ts-ignore
            x={d => dateScale(getDate(d))}
            // @ts-ignore
            y={d => positionScale(getPosition(d))}
            stroke={strokeLine}
            strokeWidth={1.3}
            strokeOpacity={0.8}
            shapeRendering="geometricPrecision"
            onMouseMove={handleMouseMove}
            // onMouseLeave={() => hideTooltip()}
          />
          <AxisLeft
            hideTicks
            tickValues={positionTicks}
            scale={positionScale}
            // @ts-ignore
            tickFormat={formatPosition}
            stroke={strokeLine}
            tickStroke={strokeLine}
            tickLabelProps={() => ({
              fill: strokeLine,
              fontSize: 11,
              textAnchor: "end",
              dy: "0.33em",
            })}
          />
          <AxisBottom
            top={yMax}
            scale={dateScale}
            // @ts-ignore
            tickFormat={formatDate}
            stroke={strokeLine}
            tickStroke={strokeLine}
            numTicks={2}
            tickValues={[minDate, maxDate]}
            tickLabelProps={() => ({
              fill: strokeLine,
              fontSize: 11,
              textAnchor: "middle",
            })}
          />
        </Group>
        {/*{tooltipData && (*/}
        {/*  <g>*/}
        {/*    <Line*/}
        {/*      from={{ x: tooltipLeft, y: margin.top }}*/}
        {/*      to={{ x: tooltipLeft, y: yMax + margin.top }}*/}
        {/*      stroke={accentColorDark}*/}
        {/*      strokeWidth={2}*/}
        {/*      pointerEvents="none"*/}
        {/*      strokeDasharray="5,2"*/}
        {/*    />*/}
        {/*    <circle*/}
        {/*      cx={tooltipLeft}*/}
        {/*      cy={tooltipTop + 1}*/}
        {/*      r={4}*/}
        {/*      fill="black"*/}
        {/*      fillOpacity={0.1}*/}
        {/*      stroke="black"*/}
        {/*      strokeOpacity={0.1}*/}
        {/*      strokeWidth={2}*/}
        {/*      pointerEvents="none"*/}
        {/*    />*/}
        {/*    <circle*/}
        {/*      cx={tooltipLeft}*/}
        {/*      cy={tooltipTop}*/}
        {/*      r={4}*/}
        {/*      fill={accentColorDark}*/}
        {/*      stroke="white"*/}
        {/*      strokeWidth={2}*/}
        {/*      pointerEvents="none"*/}
        {/*    />*/}
        {/*  </g>*/}
        {/*)}*/}
      </svg>
      {/*{tooltipData && (*/}
      {/*  <div className="w-10 h-10">*/}
      {/*    <Tooltip top={tooltipTop - 12} left={tooltipLeft + 12} style={tooltipStyles}>*/}
      {/*      {`$${getPosition(tooltipData)}`}*/}
      {/*    </Tooltip>*/}
      {/*    <Tooltip*/}
      {/*      top={yMax - 14}*/}
      {/*      left={tooltipLeft}*/}
      {/*      style={{*/}
      {/*        ...defaultStyles,*/}
      {/*        minWidth: 72,*/}
      {/*        textAlign: "center",*/}
      {/*        transform: "translateX(-50%)",*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      {formatDate(getDate(tooltipData))}*/}
      {/*    </Tooltip>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
}


function generatePoints(points: TimeSeriesPoint[]) {
  if(!points) return []
  points.forEach(p => p.time = new Date(new Date(p.time).setHours(0, 0, 0, 0)))
  console.log("generatePoints", points);

  const dateScale = scaleTime<number | undefined>({
    domain: [minDate, maxDate],
  });

  // const date = new Date(points[0].time);
  // // const allPoints = [points[0]]
  // const allPoints = []
  // let i = 0
  // // points.shift()
  // while(date < maxDate) {
  //   console.log('current date', date, points[i].time)
  //   if(points[i].time.valueOf() === date.valueOf()) {
  //     console.log('item found', allPoints, points)
  //     allPoints.push(points[i])
  //     i++
  //   } else {
  //     console.log('new item', allPoints, points)
  //     allPoints.push({time: new Date(date), position: 96})
  //   }
  //   date.setDate(date.getDate() + 1);
  //   // console.log("date", date);
  // }
  // console.log("allPoints", allPoints, points);

  console.log("dateScale:", { dateScale });
  console.log("minMaxDate:", { minMaxDate: extent(points, getDate) });
  console.log("ticks:", { ticks: dateScale.ticks() });
  return points;
}

function RankingChart({ points }: { points: TimeSeriesPoint[] }) {
  const { theme } = useTheme();
  const strokeLine = theme === "dark" ? "#fff" : "#000";
  return (
    <div>
      <ParentSize>
        {parent => (
          <LineChart
            width={parent.width}
            height={180} // 120 in figma
            points={generatePoints(points)}
            strokeLine={strokeLine}
          />)
        }
      </ParentSize>
    </div>);
}

export default RankingChart;
