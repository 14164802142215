import { HTMLAttributes, ReactNode } from "react";
import cx from "classnames";
import React from "react";
import s from "./Card.module.css";

export type Variant = "gray" | "green" | "orange" | "yellow";

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * default is gray
   */
  variant?: Variant;
  /**
   * The content shown inside the card
   */
  children: ReactNode | string;
  /**
   * add custom css classes
   */
  className?: string;
}

/**
 * A simple Component to render a "card" style around its children.
 * Note: this differs from normal
 * @param children
 * @param {Variant} variant
 * @param className
 * @param rest
 * @constructor
 */
function Card({ children, variant = "gray", className, ...rest }: CardProps) {
  const classes = cx(
    s.card,
    {
      [s.bgGray]: variant === "gray",
      [s.bgGreen]: variant === "green",
      [s.bgOrange]: variant === "orange",
      [s.bgYellow]: variant === "yellow",
    },
    className
  );
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
}

export default Card;
