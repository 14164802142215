import useSWR from "swr";
import { JsonApiSuccessResponse } from "@your-org/core-lib";
import { StatusData } from "@/pages/api/product/status";

export type StatusR = JsonApiSuccessResponse<StatusData>

export function useStatus() {
  const url = `/api/product/status`;
  const { data, error, isValidating } = useSWR<StatusR>(url);
  console.log("hook:useStatus,", data, error, url);

  const loading = !data;
  const upcomingReleasesMain = data?.data.upcomingReleasesMain || 0;
  const upcomingReleasesTotal = data?.data.upcomingReleasesTotal || 0;
  const lastImportMain = data?.data.lastImportMain || 0;
  const lastImportTotal = data?.data.lastImportTotal || 0;
  const newCountMain = data?.data.newCountMain || 0;
  const newCountTotal = data?.data.newCountTotal || 0;
  const postponedCountMain = data?.data.postponedCountMain || 0;
  const postponedCountTotal = data?.data.postponedCountTotal || 0;

  const upcoming = `${upcomingReleasesMain} (${upcomingReleasesTotal})`;
  const lastImport = `${lastImportMain} (${lastImportTotal})`;
  const newCount = `${newCountMain} (${newCountTotal})`;
  const postponedCount = `${postponedCountMain} (${postponedCountTotal})`;

  return {
    upcoming,
    lastImport,
    newCount,
    postponedCount,
    loading,
    validating: isValidating,
    error,
  };
}
