import cn from "classnames";
import { ButtonHTMLAttributes, JSXElementConstructor, ReactNode } from "react";
import s from "./Button.module.css";
import { Loading } from "../../icons";

export type ButtonVariants = "black" | "btn-theme" | "white" | "gray";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * What background color to use
   */
  variant?: ButtonVariants;
  /**
   * Extend the button to "full width" (parent)
   */
  width?: "full" | "normal";
  /**
   * old way of adding text for button
   */
  text?: string;
  /**
   * when button is toggled it switch the default color with the hover color
   */
  toggled?: boolean;
  /**
   * show a loading spinner inside the button
   */
  loading?: boolean;
  /**
   * when the button cannot be used
   */
  disabled?: boolean;
  /**
   * add custom css classes
   */
  className?: string;
  /**
   * place a icon right to the 'text' and left to the 'children'
   * - this comes through the old button usage and it is bedder to just use children for text + icon
   */
  icon?: ReactNode;
  /**
   * add the button style to another component than <button>
   */
  Component?: string | JSXElementConstructor<any>;
}

/**
 * Primary UI component for user interaction
 */
function Button({
  className,
  variant = "btn-theme",
  text,
  children,
  width = "normal",
  toggled = false,
  loading = false,
  disabled = false,
  icon,
  Component = "button",
  ...rest
}: ButtonProps) {
  const rootClassName = cn(
    s.root,
    {
      [s.black]: variant === "black",
      "btn-theme": variant === "btn-theme",
      [s.gray]: variant === "gray",
      [s.white]: variant === "white",
      [s.fullWidth]: width === "full",
      [s.loading]: loading,
      [s.toggled]: toggled,
    },
    className,
  );

  return (
    <Component
      className={rootClassName}
      disabled={disabled}
      style={{
        width,
      }}
      {...rest}
    >
      {!loading ? (
        <>
          {text}
          {icon}
          {children}
        </>
      ) : (
        <i className="pl-4">
          <Loading />
        </i>
      )}
    </Component>
  );
}

export default Button;
