export const FILTER = {
  NEW: "new",
  POSTPONED: "postponed",
  LABEL: "label",
  CW: "cw",
  SEARCH: "search",
  DATE: "date", // for snapshots
  STATUS: "status",
  // DATE: 'date',
  // FROM: 'from',
  // TO: 'to',
};

export type Filter = typeof FILTER[keyof typeof FILTER];

// new one with lower case values
export const SORT = {
  UP: "asc", // Ascending/Aufsteigend: 0, 1, 2, 3, a, b, c, d
  DOWN: "desc", // Descending/Absteigend: 4, 3, 2, 1, 0, d, c, b, a
};

export type SortOrder = typeof SORT[keyof typeof SORT];

export const ORDER_BY = {
  PRODUCT: {
    CURRENT_POSITION: "currentPosition",
    HIGHEST_POSITION: "highestPosition",
    RANKED_DAYS: "rankedDays",
    ARTIST: "artist",
  },
};

export type ProductOrderByKeys =
  typeof ORDER_BY.PRODUCT[keyof typeof ORDER_BY.PRODUCT];

export const STATUS = {
  NEW: "isNew",
  POSTPONED: "isPostponed",
  UPCOMING: "isUpcoming",
  LATEST: "isLatest",
};


export const WITHIN_SEVEN_DAYS = new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000);
export const TODAY: Date = new Date(new Date().setHours(0, 0, 0, 0));
export const TWENTY_EIGHT_DAYS_AGO: Date = new Date(new Date().setHours(0, 0, 0, 0) - 28 * 24 * 60 * 60 * 1000);
