import React, { useEffect, useState } from "react";
import { DarkModeToggle, Logo, NavItem, Row, Text } from "@your-org/ui-lib";
import { useTheme } from "next-themes";
import { signOut } from "next-auth/react";

function ModeToggle() {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  // https://github.com/pacocoursey/next-themes#avoid-hydration-mismatch
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return <DarkModeToggle theme={theme} setTheme={setTheme} />;
}


/**
 * Note: always shows all dark
 * @param text
 * @constructor
 */
export function HeaderText({ text }: { text: string }) {

  return <Row className="p4">
    <div className="p-4">
      <Logo href="/" text={["Release", "Tracker"]} logoDark />
    </div>
    <Text variant="big" className="p-1 text-black uppercase">
      {text}
    </Text>
  </Row>;

}


export function MainHeader() {
  const { theme } = useTheme();

  function logout() {
    signOut();
  }

  const snapshotUrl = "/snapshot?date=" + new Date().toLocaleDateString("en");
  return (
    <nav className="py-4 px-4 flex w-full bg-white dark:bg-black">
      <Logo href="/" text={["Release", "Tracker"]} logoDark={theme === "light"} />
      <ul className="flex justify-between space-x-8 md:space-x-16 lg:space-x-20 xl:space-x-24">
        <div className="flex flex-col">
          <NavItem href="/">Calender Week</NavItem>
          <NavItem href={snapshotUrl}>Charts Import</NavItem>
        </div>
        <div className="flex flex-col">
          <NavItem onClick={logout}>
            LOG OUT
          </NavItem>
          <ModeToggle />
        </div>
      </ul>
    </nav>
  );
};
