import { motion } from "framer-motion";
import { useClickOutside } from "@mantine/hooks";
import { Text } from "../Text";
import cn from "classnames";
import { HTMLAttributes, ReactNode } from "react";
import s from "./FilterModal.module.css";

type BackgroundVariants = "theme" | "gray";

interface FilterModalProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * indicates if modal should be shown or not
   */
  show: boolean;
  /**
   * indicates if modal should be shown a X for closing the modal or not - true by default
   */
  showClose?: boolean;
  /**
   * Callback that get trigger when the modal should be closed
   */
  close: () => void;
  /**
   * A text to be shown as title in the modal
   */
  text?: string;
  /**
   * define the background color of the modal, default is bg-theme
   */
  background?: BackgroundVariants;
  /**
   * For modals with text you can add a p-4 padding with this boolean
   */
  padding?: boolean;
  /**
   * The content that should be shown inside the modal
   */
  children: ReactNode | string;
  /**
   * The content that should be shown inside the modal
   */
  className?: string;
}

/**
 * A animated modal
 * example of a full modal https://gist.github.com/igoldny/c40d36c8863d525a581391b76371c086
 * It has a big padding top cause its opening ofter under a button
 * @param show
 * @param children
 * @param close function get trigger when the modal should be closed
 * @param {string} text shown a title in the modal
 * @param {BackgroundVariants} background
 * @param showClose
 * @param className
 * @param padding
 * @constructor
 */
function FilterModal({
  show = false,
  children,
  close,
  text = "add filter",
  background = "theme",
  showClose = true,
  className,
  padding = false
}: FilterModalProps) {
  const ref = useClickOutside(close);
  if (!show) return null;

  const modalStyle = cn(s.modal, className);
  const bgStyle = cn(s.bg, {
    "bg-theme": background === "theme", // todo not working but only used in PC
    [s.bgGray]: background === "gray",
  }, padding && 'p-4');

  return (
    <motion.div
      className={modalStyle}
      key="modal"
      initial={{ opacity: 0, scale: 0.96, y: 0 }}
      animate={{ opacity: 1, scale: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0 }}
      transition={{ ease: [0.16, 1, 0.3, 1], duration: 0.25 }}
    >
      <div
        ref={ref}
        className={bgStyle}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="options-menu"
      >
        {showClose && <div className="flex justify-between">
          <Text variant="h3" className="pr-2">
            {text}
          </Text>
          <svg
            className="fill-current text-black cursor-pointer"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            onClick={close}
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
          </svg>
        </div>}
        {children}
      </div>
    </motion.div>
  );
}

export default FilterModal;
