import { Text } from "@your-org/ui-lib";
import style from "./ScrapingGrid.module.css";

import { ProductExt } from "@/pages/api/product";
import { ScrapingGridItem } from "./ScrapingGridItem";
import { NoResults } from "@/components/NoResults";

function GridHeader({ text = "" }) {
  return (
    <div className="bg-white dark:bg-black">
      <Text>{text}</Text>
    </div>
  );
}

export function GridLoadingRow() {
  return (
    <div className="px-8 grid grid-flow-row grid-cols-8 rounded bg-theme-light mx-4 h-16 items-center">
      <div className="h-3 mr-6 bg-theme rounded animate-pulse" />
      <div className="w-12 h-3 mr-6 bg-theme rounded animate-pulse" />
      <div className="w-12 h-3 mr-6 bg-theme rounded animate-pulse" />
      <div className="h-3 mr-6 bg-theme rounded animate-pulse" />
      <div className="h-3 mr-6 bg-theme rounded animate-pulse" />
      <div className="h-3 mr-6 bg-theme rounded animate-pulse" />
      <div className="h-3 mr-6 bg-theme rounded animate-pulse" />
      <div className="h-3 mr-6 bg-theme rounded animate-pulse" />
    </div>
  );
}

type ScrapingGridProps = { products?: ProductExt[]; loading?: boolean; showRelatedProducts?: boolean };

export function ScrapingGrid({
  products = [],
  loading = false,
  showRelatedProducts = false,
}: ScrapingGridProps) {
  return (
    <>
      <div className={style.container}>
        <div className={style.header}>
          <GridHeader text="" />
          <GridHeader text="" />
          <GridHeader text="Artist" />
          <GridHeader text="Title" />
          <GridHeader text="Label" />
          <GridHeader text="Configuration" />
          <GridHeader text="Price" />
          <GridHeader text="Current rank" />
          <GridHeader text="Highest rank" />
          <GridHeader text="Ranked days" />
        </div>
        {/*// todo add No Resolute again */}
        {/*{!loading && !scrapingData.length && (*/}
        {/*  <NoResults description="Unfortunately we could not find any scraping data." />*/}
        {/*)}*/}
        {/*{loading && [...Array(10)].map((_, i) => <GridLoadingRow key={i} />)}*/}

        {products?.map((product, i) => (
          <ScrapingGridItem key={`${product.asin}-${i}`} product={product} show={showRelatedProducts}/>
        ))}
      </div>
    </>
  );
}
