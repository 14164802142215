import { useTheme } from "next-themes";
import { ProgressBar } from "react-loader-spinner";
import { Row } from "@your-org/ui-lib";

interface LoadingProgressProps {
}

export function LoadingProgress({}: LoadingProgressProps) {
  const { theme } = useTheme();
  const borderColor = theme === "dark" ? "white" : "black";
  const barColor = theme === "dark" ? "#272b2c" : "#D1D1D1";
  return (
    <Row center className="justify-center h-auto">
      <ProgressBar
        height="160"
        width="100"
        ariaLabel="progress-bar-loading"
        // wrapperStyle={{height: "2rem"}}
        wrapperClass="progress-bar-wrapper"
        borderColor={borderColor}
        barColor={barColor}
      />
    </Row>
  );
}

export default LoadingProgress;
